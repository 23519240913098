<template>
  <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; width: 100%; height: 100%; ">
    <div style="text-align: center"><h1>Вы успешно завершили регистрацию, ожидайте оповещения на почту об активации вашей учетной записи</h1></div>
    <div><a href="/signin"><h4>вернуться на страницу входа</h4></a></div>
  </div>
</template>

<script>
  export default {
    name: 'RegistrationDone',
    data() {
      return {}
    }
  }
</script>
